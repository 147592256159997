import React from 'react'
import Layout from '../components/Layout'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/SEO'
import TitleArea from '../components/TitleArea'
import { Container, Columns, Column, Content, Title, Section } from 'bloomer'
import GroomingGallery from '../components/GroomingGallery'

export default ({ data }) => (
  <Layout>
    <SEO title="Dog Grooming" alias="dog-grooming" />
    <TitleArea
      title="Dog Grooming"
      subtitle="We want your dog to look and feel their best"
    />
    <Section>
      <Container className="container--narrow">
        <Columns>
          <Column>
            <Content>
              <p>We offer dog grooming services for dogs of all sizes.</p>
              <p>Our services include:</p>
              <ul>
                <li>Combing</li>
                <li>Bathing</li>
                <li>Blow drying</li>
                <li>Nail clipping</li>
                <li>Hair clipping</li>
              </ul>
              <p>
                All grooming is done by appointment only, please contact us so
                we’ll know when to expect you.
              </p>
              <p>
                Pre-checkout bathing is also available so your pet can be
                feeling and looking their best when you come to take them home
                after boarding with us.
              </p>
            </Content>
            <Title
              tag="h2"
              // hasTextAlign="centered"
            >
              Rates
            </Title>
            <Content>
              <p>
                Rates vary by dog breed and size, please contact us to get a
                quote.
              </p>
            </Content>
          </Column>

          <Column isSize="narrow">
            <Img fixed={data.coverPhoto.childImageSharp.fixed} />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Content>
              <h2>Grooming Agreement</h2>
              <p>
                A standard{' '}
                <Link to="/grooming-agreement/">
                  grooming agreement
                </Link>{' '}
                is signed by both Shady Lane Boarding Kennel and the Pet Owner
                upon arrival.
              </p>
              <h2>Vaccinations</h2>
              <p>
                We have a{' '}
                <Link to="/vaccination-policy/">
                  vaccination policy
                </Link>{' '}
                that includes bordetella and proper documentation is required at
                each visit to the kennel.
              </p>
            </Content>
            <Title tag="h2">Some of our Happy Customers</Title>
            <GroomingGallery />
          </Column>
        </Columns>
      </Container>
    </Section>
  </Layout>
)

export const query = graphql`
  query {
    coverPhoto: file(relativePath: { eq: "dog-grooming/cover.jpg" }) {
      childImageSharp {
        fixed(width: 411, height: 308) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
