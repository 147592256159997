import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { PhotoSwipeGallery } from 'react-photoswipe'

let options = {
  //http://photoswipe.com/documentation/options.html
  history: false,
  captionEl: false,
  fullscreenEl: false,
  zoomEl: false,
  shareEl: false,
}

let getThumbnailContent = item => <Img fixed={item.thumbnail} />

export default () => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(
          filter: { relativePath: { regex: "/dog-grooming/gallery/" } }
        ) {
          edges {
            node {
              ...GalleryImages
            }
          }
        }
      }
    `}
    render={data => {
      let items = data.images.edges.map(image => {
        let fullSize = image.node.fullSize.resize
        let thumbnail = image.node.thumbnail.fixed

        return {
          src: fullSize.src,
          thumbnail,
          w: fullSize.width,
          h: fullSize.height,
        }
      })

      return (
        <PhotoSwipeGallery
          items={items}
          options={options}
          thumbnailContent={getThumbnailContent}
        />
      )
    }}
  />
)
